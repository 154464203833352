import $ from 'jquery';
window.$ = window.jQuery = $;

import 'owl.carousel/dist/assets/owl.carousel.css';
import('owl.carousel').then(() => {
    const $carousel = $('.owl-carousel-banques');
    var items = $('.owl-carousel-banques .slider-banque-item');

    items.sort(function() {
        return Math.random() - 0.5;
    });
    $carousel.empty();

    $.each(items, function(index, item) {
        $carousel.append(item);
    });

    $carousel.each(function () {
        var $carouselEl = $(this);
        $carouselEl.owlCarousel({
            loop: true,
            margin: 10,
            nav: false,
            dots: true,
            items: 3,
            autoWidth:true,
            center: true,
            rewind: false,
            smartSpeed: 250,
            fluidSpeed: 250,
            navSpeed: 250,
            dotsSpeed: 250,
            dragEndSpeed: 250,
            video: true,
            onInitialized: function () {
                $carouselEl.find('.owl-item.center').addClass('center-active');
            },
            onDrag: function () {
                $carouselEl.find('.owl-item').removeClass('center-active')
                $carouselEl.find('.owl-item').addClass('active');
            },
            //slideTransition: 'linear'
        });
        $carouselEl.on('translated.owl.carousel', function(event) {
            $carouselEl.find('.owl-item').removeClass('center-active');
            $carouselEl.find('.owl-item.center').addClass('center-active');
        })
    });
});


